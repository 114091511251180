
import hasPageMeta from '@/mixins/hasPageMeta'
import { mapState } from 'vuex'

export default {
    name: 'Content',

    mixins: [hasPageMeta],

    async fetch() {
        let url = this.$route.path
        if (url.startsWith('/brands/')) url = url.replace('/brands/', '/vendors/')
        if (url.startsWith('/amp/')) this.$nuxt.context.redirect(301, url.replace('/amp/', '/'))
        if (await this.jsonPageLookup(url)) {
            this.isJson = true
            return
        }
        this.errorCode = null
        await this.$axios
            .$get(`/api/v1/content?url=${url}`)
            .then((res) => {
                if (res.redirect) this.$nuxt.context.redirect(res.code || 301, res.redirect)
                this.content = res.data
            })
            .catch((e) => {
                this.errorMsg = e && e.response && e.response.statusText ? e.response.statusText : null
                this.errorCode = e.response.status
            })
        if (this.errorCode && process.server) {
            this.$nuxt.context.res.statusCode = this.errorCode
        }
    },

    data() {
        return {
            content: {},
            isJson: false,
            errorMsg: null,
            errorCode: null,
        }
    },

    computed: {
        ...mapState({
            jsonData: (state) => state.jsonloader.data || {},
        }),

        siteTitle() {
            if (this.isJson && this.content && this.content.meta && this.content.meta.title) return this.content.meta.title
            return `${this.content && this.content.name ? this.content.name : undefined}`
        },

        siteDescription() {
            if (this.isJson && this.content && this.content.meta && this.content.meta.description) return this.content.meta.description
            return this.content && this.content.excerpt ? this.content.excerpt : this.errorCode ? this.errorMsg : undefined
        },

        pageComponent() {
            if (this.isJson) return 'loverly-v2-content-page-json'
            return this.errorCode ? 'loverly-v2-content-page-error' : 'loverly-v2-content'
        },

        previewImage() {
            if (this.isJson && this.content && this.content.meta && this.content.meta.image) return this.content.meta.image
            return this.content && this.content.cover && this.content.cover.url ? this.content.cover.url : `${process.env.baseUrl}/icon.png`
        },
    },

    watch: {
        '$fetchState.pending'(val) {
            if (this.$nuxt.$loading)
                this.$nextTick(() => {
                    if (val) this.$nuxt.$loading.start()
                    else this.$nuxt.$loading.finish()
                })
        },
    },

    methods: {
        async jsonPageLookup(url) {
            // If url has multiple slashes, remove all but one
            while (url.includes('//')) url = url.replace(/\/\//g, '/')
            // Remove trailing slash
            while (url.endsWith('/')) url = url.replace(/\/$/, '')
            if (!(await this.$store.dispatch('jsonloader/fetchJson', `pages.json`))) return false
            if (this.jsonData && this.jsonData['pages.json']) {
                // [{url: '...', content: {}}]
                const data = this.jsonData['pages.json'] || []
                for (const item of data) {
                    if (item.url == url) {
                        if (item.redirect && item.redirect.url) {
                            this.$nuxt.context.redirect(item.redirect.code || 301, item.redirect.url)
                            return true
                        }
                        if (item.include) {
                            if (await this.$store.dispatch('jsonloader/fetchJson', item.include)) {
                                if (this.jsonData && this.jsonData[item.include]) {
                                    const includeData = this.jsonData[item.include] || []
                                    if (includeData) {
                                        this.content = includeData
                                        return true
                                    }
                                }
                            }
                        }
                        if (item.content) {
                            this.content = item.content
                            return true
                        }
                    }
                }
            }
            return false
        },
    },
}
